import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "section-border"
};
const _hoisted_2 = {
  class: "select-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_tree = _resolveComponent("el-tree");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_divider, {
    direction: "vertical"
  }), _createTextVNode(" 部门列表 ")]), _withDirectives(_createVNode(_component_el_tree, {
    data: $data.organizationList,
    props: $data.defaultProps
  }, null, 8, ["data", "props"]), [[_directive_loading, _ctx.$store.state.system.organizationLoading]])]);
}